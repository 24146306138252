/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzJjNDI1NSIsIj9lcjwjYzBjNmNjIiwiO2VyPCMxYjJiM2J$LCIlPmBePCMxMzczOTAiLCI~ZXI8I2I4ZDVkZSIsIjtlcjwjMGI1Njczfiwid2Fybj5gXjwjYmUxZTJkIiwiP2VyPCNlY2JjYzAiLCI7ZXI8I2E3MTIxY34sIj9UZXh0PCMwMDBlMGUiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEzfQ==
*/

@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

// @import url("https://use.typekit.net/zls8zlz.css");

$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 300, 'sans-serif', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'sans-serif', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'sans-serif', 0.0000em),
  display-1: mat.define-typography-level(34px, 40px, 400, 'sans-serif', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, 'sans-serif', 0.0000em),
  title: mat.define-typography-level(20px, 32px, 500, 'sans-serif', 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, 'sans-serif', 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, 'sans-serif', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, 'sans-serif', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'sans-serif', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'sans-serif', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, 'sans-serif', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'sans-serif', 1.5px)
);

// Foreground Elements

// Light Theme Text
$dark-text: #000e0e;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
// sk-dev: ERRORS in v15
// @include mat.core($fontConfig);

// Theme Config

body {
  --primary-color: #2c4255;
  --primary-lighter-color: #c0c6cc;
  --primary-darker-color: #1b2b3b;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
  --accent-color: #137390;
  --accent-lighter-color: #b8d5de;
  --accent-darker-color: #0b5673;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
  --warn-color: #be1e2d;
  --warn-lighter-color: #ecbcc0;
  --warn-darker-color: #a7121c;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-primary: (
  main: #2c4255,
  lighter: #c0c6cc,
  darker: #1b2b3b,
  200: #2c4255,
  // For slide toggle,
  contrast : (main: $light-primary-text, lighter: $dark-primary-text, darker: $light-primary-text, )
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

$mat-accent: (
  main: #137390,
  lighter: #b8d5de,
  darker: #0b5673,
  200: #137390,
  // For slide toggle,
  contrast : (main: $light-primary-text, lighter: $dark-primary-text, darker: $light-primary-text, )
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

$mat-warn: (
  main: #be1e2d,
  lighter: #ecbcc0,
  darker: #a7121c,
  200: #be1e2d,
  // For slide toggle,
  contrast : (main: $light-primary-text, lighter: $dark-primary-text, darker: $light-primary-text, )
);

$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$theme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: false,
  foreground: $mat-light-theme-foreground,
  background: $mat-light-theme-background,
  typography: $fontConfig,
);
$altTheme: (
  primary: $theme-primary,
  accent: $theme-accent,
  warn: $theme-warn,
  is-dark: true,
  foreground: $mat-dark-theme-foreground,
  background: $mat-dark-theme-background,
  typography: $fontConfig,
);

// Theme Init
@include mat.all-component-themes($theme);

// Errors - duplicate theme?
// .theme-alternate {
//   @include mat.all-component-themes($altTheme);
// }

// Specific component overrides, pieces that are not in line with the general theming

.mat-mdc-card-content .mat-toolbar {
  padding: 0;
}

.mdc-dialog__container {
  .mat-mdc-dialog-content {
    max-height: 40rem;
  }
}

@mixin input-field {
  background: #F5F4FB;
  border: 0.18em solid #3D4A57;
  border-radius: 0.2em;
  color: #000000;
  font-size: 1rem;
  //causing input fields to be smaller than select fields,
  //remove after all styling for optia has been applied and approved - RM
  // line-height: 1rem;
  padding: 1.6em 1em 0.8em 1em;

  .mat-mdc-floating-label {
    color: rgba(0, 0, 0, 0.72);
  }
}

@mixin form-field-error {
  .mat-mdc-form-field-error-wrapper {
    padding: 0;

    .mat-mdc-form-field-error {
      padding: 0;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.72);
    }
  }

  &.ng-invalid.ng-dirty {
    .mat-mdc-form-field-infix {
      border: 2px solid #AF3033;

      .mat-mdc-select-arrow,
      .mat-mdc-floating-label {
        color: #AF3033;
      }
    }

    .mat-mdc-form-field-error {
      color: #AF3033;
    }
  }
}

@mixin form-field-disabled {
  &.mat-form-field-disabled {
    .mat-mdc-form-field-infix {
      background: #F5F4FB;
      border: 2px solid #000000;
      color: #808080;
      opacity: 0.6;
      border-opacity: 0.6;

      .mat-mdc-select-arrow,
      .mat-mdc-floating-label {
        color: #808080;
      }
    }
  }
}

// page title with a 'add' button on the right
.title-with-button-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}


// Input
.mat-mdc-form-field.mat-mdc-form-field-type-mat-input {
  width: 208px;

  &.lg {
    width: 440px;
  }

  &.small {
    width: 120px;
  }

  .mat-mdc-text-field-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field-infix {
    @include input-field;

    .mat-icon {
      position: absolute;
      top: 20px;
      right: 8px;
    }
  }

  .mat-mdc-form-field-hint-wrapper {
    padding: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.72);
  }

  @include form-field-error;
  @include form-field-disabled;
}

//Text Area
.small-textarea {
  height: 7rem !important;
}

.large-textarea {
  height: 15rem !important;
}

.add-new-dialog-content {
  padding: 2% 5%;
}

// Select
.employee-details-container,
.agency-details-page-container,
.customer-details-page-container,
.contract-details-page-container,
/*.user-group-details-container,*/
.user-group-container,
.add-user-to-group-container,
.form-element-container,
.add-new-list-container,
.add-new-report-group-container,
.add-new-report-type-container,
.add-edit-list-container,
.add-agency-contact-container,
.add-back-office-contact-container,
.edit-agency-status-container,
.edit-agency-mi-report-settings,
.address-history-container,
.confirmation-dialog-container,
.add-agency-notification,
.edit-account-level3-container,
.edit-account-level3-payment-terms,
.add-customer-agency-level2-dialog-container,
.edit-customer-agency-level2-details-container,
.add-customer-agency-level2-dialog-container,
.add-worker-dialog-container,
.edit-worker-dialog-container,
.edit-worker-address-modal-dialog,
.edit-worker-contact-details-dialog .edit-worker-type-details-dialog,
.edit-worker-contact-details-dialog,
.add-worker-type-dialog-container,
.choose-worker-type-type-container,
.add-non-paye-worker-type-dialog-container,
.reports-uploading-module,
.weekly-mi-reports-table-container,
.monthly-mi-reports-table-container,
.validation-mi-reports-table-container,
.edit-customer-address-container {
  .mat-mdc-form-field.mat-mdc-form-field-type-mat-select {
    width: 208px;

    &.lg {
      width: 440px;
    }

    .mat-mdc-text-field-wrapper {
      padding: 0;
    }

    .mat-mdc-form-field-infix {
      @include input-field;
    }

    @include form-field-error;
    @include form-field-disabled;
  }

}

//Vertical spacer between elements
.small-spacer {
  margin-left: 1.5%;
}

.small-spacer-2 {
  margin-left: 2.5%;
}

.spacer,
button {
  margin-left: 3%;
}

.form-element-container {

  mat-form-field {
    min-width: 50% !important;

    textarea {
      min-height: 5em;
    }
  }

}


// Button
a[mat-raised-button].mat-mdc-raised-button.mat-mdc-button-base,
button[mat-raised-button].mat-mdc-raised-button.mat-mdc-button-base {
  border-radius: 20px;
  padding: 0 20px;
  margin: 2px;
  box-shadow: none;
  cursor: pointer;

  &.mat-primary {
    background-color: #35728D;
    color: #FFFFFF;

    &:hover {
      background-color: #4E97B8;
    }

    &:focus {
      background: #24607A;
      box-shadow: none;
    }

    &[disabled] {
      background: #ABB4BD;
      cursor: not-allowed;
      pointer-events: auto;
    }
  }

  &.mat-warn {
    color: #AF3033;
    border: 2px solid #AF3033;

    &:hover {
      color: #CB4F52;
      border: 2px solid #CB4F52;
    }

    &:focus {
      color: #A4181B;
      border: 2px solid #A4181B;
    }

    &[disabled] {
      color: #ABB4BD;
      border: 2px solid #ABB4BD;
      cursor: not-allowed;
      pointer-events: auto;
    }
  }
}

.status-enabled {
  background-color: #568A2C;
  border-radius: 0.4rem;
  width: 9rem;
  height: 2rem;
  color: #ffffff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;

  mat-label {
    font-size: 1.1rem;
  }
}

.status-disabled {
  background-color: #AFAFAF;
  border-radius: 0.4rem;
  width: 9rem;
  height: 2rem;
  color: gray;
  text-align: center;
  display: table-cell;
  vertical-align: middle;

  mat-label {
    font-size: 1.1rem;
  }
}


// Icon Button
button[mat-icon-button] {

  &.mat-primary {
    color: #35728D;

    &:hover .mat-icon {
      color: #4E97B8;
    }

    &[disabled] {
      background: #ABB4BD;
      cursor: not-allowed;
      pointer-events: auto;
    }
  }

  &.mat-warn {
    color: #AF3033;

    &:hover .mat-icon {
      color: #CB4F52;
    }


    &[disabled] {
      color: #ABB4BD;
      border: 2px solid #ABB4BD;
      cursor: not-allowed;
      pointer-events: auto;
    }
  }
}

.mat-mdc-raised-button[disabled] {
  opacity: 0.8 !important;
  color: grey !important;
}

div.mdc-line-ripple {
  display: none;
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons', 'Material Icons';

  .mat-badge-content {
    font-family: sans-serif;
  }
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font-family: sans-serif, 'filson-pro';
  font-style: normal;
  font-weight: 100;
  background: #fafafa;
  font-size: calc(3 * (0.5vw + 0.5vh));
}

.mat-badge-warn .mat-badge-content {
  background-color: #BE1E2D;
  color: #ffffff;
}

mat-label {
  font-size: 0.8rem;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1rem;
}

p {
  font-size: 1rem;
}

// sk-dev: custom fonts from svg line icons
@font-face {
  font-family: 'optia-icons';
  src: url('assets/fonts/optia-icons.eot?n6iqew');
  src: url('assets/fonts/optia-icons.eot?n6iqew#iefix') format('embedded-opentype'), url('assets/fonts/optia-icons.ttf?n6iqew') format('truetype'), url('assets/fonts/optia-icons.woff?n6iqew') format('woff'), url('assets/fonts/optia-icons.svg?n6iqew#optia-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.material-icons.ico {
  font-family: 'optia-icons' !important;
}

.ico {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'optia-icons' !important;
  // speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-dashboard:before {
  content: "\e900";
}

// sk-dev: nav/btn glassmorphism fx?
.glass {
  position: relative;
  box-sizing: border-box;
  padding: 0;
  max-height: 90%;
  max-width: 90%;
  width: 250px;
  height: 250px;
  border: 2px solid rgba(0, 0, 0, .15);
  border-radius: 25px 25px 0 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .25), inset 0 0 2px 0px white;
  background: rgba(0, 0, 0, .0125);
  resize: both;
  overflow: hidden;
  transition: all 200ms ease-in-out;
  animation: resize 1.5s .5s;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, .025);
    z-index: -1;
  }
}

// nav/btn poc alert/indicator
.indicator {
  position: relative;
  box-sizing: border-box;
  padding: 0;
  max-height: 90%;
  max-width: 90%;
  width: 250px;
  height: 250px;
  border: 2px solid rgba(0, 0, 0, .15);
  border-radius: 25px 25px 0 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .25), inset 0 0 2px 0px white;
  background: rgba(0, 0, 0, .0125);
  resize: both;
  overflow: hidden;
  transition: all 200ms ease-in-out;
  animation: resize 1.5s .5s;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(3px);
    background-color: rgba(255, 255, 255, .025);
    z-index: -1;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: -10px;
    right: -10px;
    cursor: nwse-resize;
    border: 10px solid transparent;
    border-bottom: 10px solid #f06;
    transform: rotate(135deg);
  }
}

.mat-mdc-card-content .mat-toolbar {
  background-color: transparent;
}

.mat-datepicker-content {
  background-color: #ffffff;
  border: 10px;
}

.mat-elevation-z8 {
  border-radius: 8px;
}

// Header text
.mat-mdc-card-header-text h1 {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 32px;
}

.inbuilt-documents-table {
  .mat-mdc-table.mdc-data-table__table {
    margin-top: 1rem;
  }
}

// Table
.mat-mdc-table.mdc-data-table__table {
  background-color: #ffffff;
  margin-top: 1.4rem;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;

  .mdc-data-table__row {
    height: 56px;

    &:nth-child(2n+1) {
      background-color: #fbfbfb;
    }
  }

  .mdc-data-table__cell {
    color: #333333;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid #EBEBEB;
  }

  .mdc-data-table__header-cell {
    color: #808183;
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid #EBEBEB;

    .mat-sort-header-arrow {
      display: none;
    }

    .mat-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 8px;
      font-size: 20px;
      color: #808183;
      border-radius: 4px;

      &.sorted {
        background-color: #EDF1F0;
      }
    }

    // &:last-child {
    //   text-align: right;
    // }
  }

  .edit-details-cell,
  .details-cell {
    display: flex;
    text-decoration: none;
    color: #35728D;

    .mat-icon {
      font-size: 18px;
      margin-top: 3px;
    }
  }

  .edit-details-cell:hover,
  .details-cell:hover {
    color: #24607A;
    cursor: pointer;

    & span {
      text-decoration: underline;
    }
  }
}

// Pagination
.cdk-overlay-pane {
  //max-width: 64px !important;

  .mat-mdc-select-panel {
    background-color: #fff;
    border-radius: 4px !important;
    border: 2px solid #3D4A57;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12);

    &.mat-mdc-select-panel {
      padding: 0;
    }

    .mat-mdc-option,
    mat-mdc-menu-item {
      min-height: 24px;
      font-size: 14px;
      padding: 0 8px;

      .mdc-list-item__primary-text {
        color: #333;
      }

      &:hover {
        background-color: #EFEFEF;
      }
    }

    .mdc-list-item--selected {
      background-color: #EFEFEF;
    }
  }

  .dropdown-overlay {
    &.mdc-menu-surface {

      .mat-mdc-option,
      mat-mdc-menu-item {
        min-height: 48px;
      }
    }
  }
}

.space-left-button {
  margin-left: 1.5rem !important;
}

.row.pagination {
  display: flex;
  justify-content: flex-end;
  background: #fff;
  outline: 1px solid #EBEBEB;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;

  .col.gotoPage-label {
    display: inline-flex;
    margin-right: 8px;

    mat-label.label {
      margin: auto;
      font-size: 12px;
      color: #808183;
    }
  }

  .col.page-select {
    display: inline-flex;
    margin-right: 15px;

    input {
      max-width: 60px;
      min-width: 60px;
      margin: auto;
      border: none;
      border-bottom: 2px solid #3D4A57;
      border-radius: 0;
      background: #fff;
      font-size: 14px;
      color: #000;
      text-align: center;

      &::placeholder {
        color: #000;
      }

      &:focus-visible {
        outline: none;
      }
    }
  }

  .col.mat-paginator {
    display: inline-block;
    background-color: #fff;
    color: #333;

    .mat-mdc-paginator-page-size {
      flex-direction: row-reverse;
    }

    .mat-mdc-select-trigger .mat-mdc-select-value,
    .mat-mdc-select-arrow {
      color: #35728D;
    }

    .mat-mdc-select {
      width: 35px;
    }

    .mat-mdc-select-value {
      width: 100%;
    }

    .mat-mdc-paginator-page-size-label {
      margin-left: -20px;
      color: #808183;
    }

    .mat-mdc-paginator-icon {
      fill: #35728D;
    }

    .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
      fill: rgba(53, 114, 141, 0.5);
    }
  }

  .mdc-notched-outline {
    display: none;
  }

  .col.mat-paginator .mat-mdc-select-trigger .mat-mdc-select-value,
  .col.mat-paginator .mat-mdc-select-arrow {
    color: #35728D;
  }

}

.details-row {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;

  .details-cell-label {
    width: 40%;
    vertical-align: top;
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    flex-direction: row;

    span {
      font-size: 1rem;
      color: #656565;
    }
  }

  .details-cell-value {
    width: 55%;
    text-align: left;
    display: grid;
    align-content: center;
    overflow-wrap: anywhere;

    span {
      font-size: 1rem;
      color: #000000;
    }

    mat-checkbox {
      display: flex;
    }
  }

  .details-cell-value-full-width {
    text-align: left;
    display: grid;
    align-content: center;

    span {
      font-size: 1rem;
      color: #000000;
    }

    mat-checkbox {
      display: flex;
    }
  }
}

.space-top-2 {
  margin-top: 2rem;
}

.edit-dialog-container {

  .existing-document-name {
    font-size: 1rem;
    font-weight: bold;
    padding-left: 1rem;
  }

  .replace-existing-document-note {
    font-size: 0.8rem;
    display: flex;
  }

  .edit-form {

    position: absolute;
    padding-left: 2rem;

    & mat-form-field+mat-form-field {
      margin-left: 22px;

      mat-checkbox.archived-checkbox {
        background-color: dimgray;
      }
    }

    & mat-form-field {
      margin-bottom: 8px;
    }

    .details-section {
      .tooltip-container {
        span.tooltip {
          font-size: 1rem;
          line-height: 1rem;
        }
      }
    }

    .save-button {
      position: relative;
      right: -15rem;
    }

    .cancel-button {
      position: relative;
      right: -15rem;
    }

    .action-controls-section {
      padding-top: 1rem;
    }

    .mat-mdc-form-field-error {
      padding: 0;
      font-size: 0.9rem;
      color: #AF3033;
    }
  }

  .note {
    font-size: 14px;
  }

  .move-right {
    position: relative;
    float: right;

  }

  .file-uploader-control {
    width: 30rem;
    min-height: 5rem;
    border: 0.1rem dashed #000000;
    margin-top: 1rem;
    box-sizing: content-box;
    border-radius: 0.6rem;
    position: relative;

    .file-uploading-name {
      font-size: 0.9rem;
      color: #000000;
      max-width: 22rem;
      position: absolute;
      top: 50%;
      left: 3%;
      transform: translate(0, -50%);
      word-wrap: break-word;
    }

    .file-uploading-upload-button {
      border: 0.15rem solid #35728D;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(0, -50%);
      border-radius: 10rem;
      min-width: 14rem;

      a {
        font-size: 1rem;
        text-decoration: none;
        color: #35728D;
        margin: auto;
        vertical-align: middle;
        line-height: 3rem;
        padding-left: 4.2rem;
        cursor: pointer;

        .upload-cloud-icon {
          color: #35728D;
          line-height: 2rem;
          height: 2rem;
          width: 2rem;
          display: block;
          font-size: 1.7rem;
          margin-right: 2.6rem;
          padding-left: 1.2rem;
          position: absolute;
          top: 50%;
          transform: translate(15%, -50%);
        }
      }
    }

    &.file-dragged-over {
      border: 0.2rem dashed #BE1622;
      background-color: #95C1CD;
    }


  }

  .document-upload-label {
    display: flex;
  }

  .invisible-input-file-tag {
    height: 0;
  }
}

.header-split {
  display: flex;
  justify-content: space-between;
}

.edit-card-icon-button {
  text-align: right;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-between;
  align-items: center;
  justify-content: flex-end;

  &.display-history-icon {
    height: 5rem;
  }

  .mat-icon.material-icons.mat-icon-inline {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
  }
}