// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import './critical';

// theme
@import './optia-theme';

.mat-cell-break-anywhere {
  overflow-wrap: anywhere;
}

.mat-mdc-paginator-range-label {
  width: 10rem;
}

h1 {
  font-size: 2rem;
  font-weight: 700;
  color: #333;
  margin: 1rem;
  padding: 0;
}

h2 {
  font-size: 1.2rem;
  font-weight: 500;
  color: #333;
  margin: 1rem;
  padding: 0;
}

.error-message {
  display: block;
  color: #AF3033;
  font-size: 1rem;
  font-weight: 300;
}

.warning-message {
  display: block;
  color: #D6971E;
  font-size: 1rem;
  font-weight: 300;
}

.information-message {
  background: url('assets/information-alert.svg') top left no-repeat;
  padding-left: 1.8rem;
  display: block;
  color: #D6971E;
  font-size: 1rem;
  font-weight: 300;
  padding-bottom: 1.2rem;
  margin-top: -1.1rem;
}

.loading-indicator-middle-screen {
  background-color: #6A7883;
  opacity: 50%;
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: absolute;

  .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
    z-index: 1001;

    .spinner-loading-indicator::ng-deep {
      circle {
        stroke: #a7121c !important;
      }
    }
  }
}

.upload-new-document {
  margin-top: 1.5rem;
  background-color: #ffffff;

  .upload-new-document-title {
    font-size: 1rem;
    color: #656565;
    display: block;
  }

  .upload-new-document-restrictions {
    padding-top: 1rem;
    font-size: 1rem;
    color: #656565;
    display: block;
  }

  .upload-new-document-type {
    padding-top: 0.5rem;
  }

  .select-document-type {
    font-size: 1rem;
    color: #656565;
  }

  ::ng-deep.document-types {
    margin-left: 1.5rem;
    width: 10rem;
  }

  ::ng-deep.mandatory-field {
    font-size: 1rem;
    color: #BE1622;
    display: block;
  }

  .file-uploader-control {
    width: 40rem;
    min-height: 5rem;
    border: 0.1rem dashed #000000;
    margin-top: 1rem;
    box-sizing: content-box;
    border-radius: 0.6rem;
    position: relative;

    .file-uploading-name {
      font-size: 0.9rem;
      color: #000000;
      max-width: 22rem;
      position: absolute;
      top: 50%;
      left: 3%;
      transform: translate(0, -50%);
      word-wrap: break-word;
    }

    .file-uploading-upload-button {
      border: 0.15rem solid #35728D;
      position: absolute;
      left: 60%;
      top: 50%;
      transform: translate(0, -50%);
      border-radius: 10rem;
      min-width: 14rem;

      a {
        font-size: 1rem;
        text-decoration: none;
        color: #35728D;
        margin: auto;
        vertical-align: middle;
        line-height: 3rem;
        padding-left: 4.2rem;
        cursor: pointer;

        .upload-cloud-icon {
          color: #35728D;
          line-height: 2rem;
          height: 2rem;
          width: 2rem;
          display: block;
          font-size: 1.7rem;
          margin-right: 2.6rem;
          padding-left: 1.2rem;
          position: absolute;
          top: 50%;
          transform: translate(15%, -50%);
        }
      }
    }

    &.file-dragged-over {
      border: 0.2rem dashed #BE1622;
      background-color: #95C1CD;
    }
  }

  .confirm-start-file-upload {
    width: 40rem;
    min-height: 5rem;
    border: 0.1rem dashed #000000;
    margin-top: 0;
    box-sizing: content-box;
    border-radius: 0.6rem;
    position: relative;

    .center-info-section {
      position: absolute;
      top: 50%;
      transform: translate(6%, -50%);

      .file-uploading-type {
        font-size: 0.9rem;
        text-decoration: none;
        color: #656565;
        margin: auto;
        vertical-align: middle;
        line-height: 1.5rem;
        cursor: pointer;
      }

      .file-uploading-name {
        font-size: 0.9rem;
        text-decoration: none;
        color: #000000;
        margin: auto;
        vertical-align: middle;
        line-height: 1.5rem;
        cursor: pointer;
      }
    }

    .file-uploading-upload-button {
      position: absolute;
      left: 63%;
      top: 50%;
      transform: translate(0, -50%);
      border-radius: 10rem;
      min-width: 18rem;

      .remove-selected-file {
        color: #BE1622;
        font-size: 2.2rem;
        line-height: 2.2rem;
        position: absolute;
        top: 50%;
        transform: translate(-120%, -50%);

        mat-icon.mat-icon {
          margin: 0;
          padding: 0;
          display: block;
        }
      }

      button.mdc-button.mat-mdc-raised-button {
        padding: 1.6rem 2.6rem;
        border-radius: 8rem;
      }
    }
  }

  .invisible-input-file-tag {
    max-height: 1.5rem;
  }

  #documentToUpload {
    visibility: hidden;
  }

}



.agency-status-live {
  background-color: #568A2C;
  border-radius: 0.4rem;
  width: 9rem;
  height: 2rem;
  color: #ffffff;
  text-align: center;
  display: table-cell;
  vertical-align: middle;

  mat-label {
    font-size: 1.1rem;
  }
}

.agency-status-live-incative {
  background-color: #95C1CD;
  border-radius: 0.4rem;
  width: 9rem;
  height: 2rem;
  color: white;
  text-align: center;
  display: table-cell;
  vertical-align: middle;

  mat-label {
    font-size: 1.1rem;
  }
}

.agency-status-left-unfinished {
  background-color: darkgrey;
  border-radius: 0.4rem;
  width: 9rem;
  height: 2rem;
  color: white;
  text-align: center;
  display: table-cell;
  vertical-align: middle;

  mat-label {
    font-size: 1.1rem;
  }
}

.agency-status-left-finished {
  background-color: #dd6811;
  border-radius: 0.4rem;
  width: 9rem;
  height: 2rem;
  color: white;
  text-align: center;
  display: table-cell;
  vertical-align: middle;

  mat-label {
    font-size: 1.1rem;
  }
}

.agency-status-Prospect {
  background-color: royalblue;
  border-radius: 0.4rem;
  width: 9rem;
  height: 2rem;
  color: white;
  text-align: center;
  display: table-cell;
  vertical-align: middle;

  mat-label {
    font-size: 1.1rem;
  }
}

.info-snackbar {
  background-color: #333;
  color: white;
}

.success-snackbar {
  background-color: #568A2C;
  color: white;
}

.error-snackbar {
  background-color: #AF3033;
  color: white;
}

.mat-mdc-no-data-row {
  td {
    padding-left: 15px;
  }
}