:root {
  --menu-width-open: 200px;
  --menu-width-closed: 64px;
}

html,
body {
  height: 100vh;
}

body {
  margin: 0;
  font-family: sans-serif, filson-pro, Calibri;
}

code,
pre {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}
code { font-size: 90%; }
pre { font-size: 14px; }
